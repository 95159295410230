import Md5 from 'md5'
import axios from 'axios'
import store from '@/store/index'
import { showToast, showFailToast } from 'vant'
import Router from '@/router/index'
import domain from '@/assets/js/global.js'
import i18n from '@/assets/js/i18n'
import { removeMyInfo } from '@/assets/js/index'
const qs = require('qs')
// const object = {}
export const Md5Keys = (key) => {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let newStr = ''// 生成8位随机字符串
  for (let i = 0; i < 8; i++) {
    newStr += str.substr(Math.floor(Math.random() * str.length), 1)
  }
  const obj = {
    ...key,
    appId: 'tea',
    time: Math.round(new Date().getTime() / 1000).toString(),
    nonceStr: newStr
  }
  const newObj = {}
  Object.keys(obj).sort().forEach((key, val) => {
    newObj[key] = obj[key]
  })
  let newStar = ''
  let num = parseInt(Object.keys(newObj).length)
  for (key in newObj) {
    num--
    newStar += key + '=' + newObj[key] + (num === 0 ? '' : '&')
  }
  return '?' + newStar + '&sign=' + Md5(newStar + 'e3de382b2bab1232s')
}
const instance = axios.create({
  baseURL: domain.testUrl,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  },
  timeout: 60000
})
// 添加响应拦截器
instance.interceptors.response.use((response) => {
  // 响应正常时候就从pendingRequest对象中移除请求
  // removePendingRequest(response);

  if (response.data.message === '请先绑定您的银行卡验证本人实名信息后再提现！') {
    showToast({
      message: i18n.global.t(response.data.message),
      type: 'fail'
    })
    setTimeout(() => {
      Router.push({ path: '/bindBankCard' })
    }, 1000)
  }
  if (response.data.code === 200) {
    if (response.data.message !== 'OK') {
      showToast({
        message: i18n.global.t(response.data.message),
        type: 'success'
      })
    }
  } else if (response.data.code === 401) {
    showFailToast({ message: i18n.global.t('登录已过期，请重新登录') })
    setTimeout(() => {
      removeMyInfo()
      Router.push({ path: '/login' })
    }, 1000)
    // 数据请求成功后
  } else if (response.data.code === 422) {
    showFailToast({ message: i18n.global.t(response.data.message) })
  }
  return response.data
}, (error) => {
  showFailToast('网络连接失败，请稍后重试！')
  // 从pending 列表中移除请求
  // removePendingRequest(error.config || {});
  // // 需要特殊处理请求被取消的情况
  // if (!axios.isCancel(error)) {
  //   // 请求重发
  //   return againRequest(error, instance);
  // }
  // 请求失败
  console.log(domain.testUrl + '地址错误')
  // console.log(location.protocol+"//"+document.domain + '地址错误')
  return Promise.reject(error)
})
// 添加响应拦截器
instance.interceptors.response.use((response) => {
  if (response.code === '请先绑定您的银行卡验证本人实名信息后再提现！') {
    showToast({
      message: i18n.global.t(response.message),
      type: 'fail'
    })
    setTimeout(() => {
      Router.push({ path: '/bindBankCard' })
    }, 1000)
  }
  if (response.code === 200) {
    if (response.message !== 'OK') {
      showToast({
        message: i18n.global.t(response.message),
        type: 'success'
      })
    }
  } else if (response.code === 401) {
    showFailToast({ message: '登录已过期，请重新登录' })
    setTimeout(() => {
      removeMyInfo()
      Router.push({ path: '/login' })
    }, 1000)
    // 数据请求成功后
  } else if (response.code === 422) {
    showFailToast({ message: i18n.global.t(response.message) })
  }
  return response
}, (error) => {
  showFailToast('网络连接失败，请稍后重试！')
  // 对响应错误做点什么
  console.log(domain.testUrl + '地址错误')
  // console.log(location.protocol+"//"+document.domain + '地址错误')
  return Promise.reject(error)
})
export const api = {
  async get (url, data, token, str, isLottery) {
    // object[str] = true
    // store.dispatch('loadMove', object)
    let res = null
    if (token) {
      res = await instance.get(url + Md5Keys(data), {
        headers: {
          // 'access-token': sessionStorage.getItem('token')
          'access-token': localStorage.getItem('token')
        }
      })
    } else {
      if (isLottery) {
        data = qs.stringify(data)
        res = await instance.get(url + '?' + data)
      } else {
        res = await instance.get(url + Md5Keys(data))
      }
    }
    if (res.code === 200) {
      if (str) {
        store.state.loading[str] = false
      }
    }
    return res
  },
  async post (url, data, token, isFormdata) {
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true
    // })
    if (token) {
      const config = {
        headers: {
          'access-token': localStorage.getItem('token')
        }
      }
      let datas
      if (isFormdata) {
        datas = data
      } else {
        datas = qs.stringify(data)
      }
      const res = await instance.post(url + Md5Keys(), datas, config)
      if (res) {
        setTimeout(() => {
          // Toast.clear()
        }, 500)
      }
      return res
    } else {
      const res = await instance.post(url + Md5Keys(), qs.stringify(data))
      if (res) {
        setTimeout(() => {
          // Toast.clear()
        }, 500)
      }
      return res
    }
  }
}
